<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <button
            class="uk-button uk-button-small-right uk-button-default"
            type="button"
            @click="back"
        >
            <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
            Back
        </button>
        <span v-if="isLoading" uk-spinner="ratio: 2" class="uk-position-center uk-margin-auto"></span>
        <div class=" uk-card uk-card-default uk-margin uk-padding" v-else-if="job_details">
            <div class="uk-overflow-auto">
                <div uk-grid class="uk-grid-large uk-margin-auto">
                    <div class="job-title uk-inline">{{job_details.title}}</div>
                    <div class="text-detail uk-inline uk-margin-auto-left">Created At: {{formatDate(job_details.created_at)}}</div>
                </div>
                <span :class="labelStatus">{{postStatus}}</span>
                <a v-if="postStatus == 'Live'"
                v-clipboard:copy="`${pwaUrl}${job_details.seo_url}`" v-clipboard:success="onCopy"
                ><img :src="`${images}/share.svg`" alt="" style="width:25px"></a>
                <div class="uk-margin-large-left text-detail uk-margin-bottom uk-margin-top">
                    <p class="uk-margin-remove">Description: {{job_details.job_description}}</p>
                    <p class="uk-margin-remove">Status Kerja: {{job_details.job_type ? job_details.job_type.name : '-'}}</p>
                    <p class="uk-margin-remove">Job Industry: {{job_details.job_industry ? job_details.job_industry.name : '-'}}</p>
                    <p class="uk-margin-remove">Fee: Rp{{formatCurrency(job_details.salary)}}/{{job_details.salary_unit}}</p>
                    <p class="uk-margin-remove">Fee Lembur: {{job_details.salary_overtime_hourly == 0
                        ? '-'
                        : `Rp${formatCurrency(job_details.salary_overtime_hourly)}/Jam`}}</p>
                    <p class="uk-margin-remove" v-if="job_details.benefits">Benefit: {{job_details.benefits}}</p>
                    <p class="uk-margin-remove">Lokasi: {{job_details.city ? job_details.city : '-'}}</p>
                    <p class="uk-margin-remove">Mulai Bekerja: {{formatDate(job_details.job_start)}}</p>
                    <p class="uk-margin-remove" v-if="job_details.working_hour_start && job_details.working_hour_end">
                        Jam Kerja: {{job_details.working_hour_start ? job_details.working_hour_start : '-'}} -
                        {{job_details.working_hour_end ? job_details.working_hour_end : '-'}}</p>
                    <p class="uk-margin-remove">Gender: {{genderData}}</p>
                    <p class="uk-margin-remove">Usia: {{job_details.age_group == null ? 'Bebas' : job_details.age_group}}</p>
                    <p class="uk-margin-remove">Pendidikan Minimal: {{job_details.education_level ? job_details.education_level.name:'-'}}</p>
                    <p class="uk-margin-remove" v-if="job_details.is_experience_needed">Dibutuhkan pengalaman kerja</p>
                    <p class="uk-margin-remove">Dibutuhkan keahlian: {{job_details.skill_needed}}</p>
                    <p class="uk-margin-remove" v-if="job_details.required_docs && job_details.required_docs.length > 0">Dibutuhkan dokumen:
                        <span v-for="(doc, x) in job_details.required_docs" :key="x">{{doc}} </span>
                    </p>
                    <template v-if="job_details.required_tests">
                        <p class="uk-margin-remove" v-for="(test, i) in job_details.required_tests" :key="i">Dibutuhkan test {{test.test_category_id.name}} minimal score {{test.minimum_score}}</p>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import moment from 'moment';
moment.locale('id');
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    data: () => ({
        images: PREFIX_IMAGE,
        job_details: null,
        isLoading: true,
    }),
    async mounted() {
        await this.setPageDetails();
    },
    computed: {
        postStatus(){
            if (this.job_details.status == 1) {
                return "Live";
            } else {
                return "Inactive";
            }
        },
        labelStatus(){
            if (this.job_details.status == 1) {
                return 'uk-margin-large-left uk-label uk-label-success';
            } else {
                return 'uk-margin-large-left uk-label uk-label-danger';
            }
        },
        genderData(){
            if (this.job_details.gender != null) {
                if (this.job_details.gender == 'male') {
                    return 'Pria';
                } else {
                    return 'Wanita';
                }
            } else {
                return "Pria / Wanita";
            }
        },
        pwaUrl(){
            if (process.env.NODE_ENV === 'production') {
                return "https://app.myrobin.id/loker/";
            } else {
                return "https://app.myrobin.tech/loker/";
            }
        },
    },
    watch:{
        $route (to, from){
            if (to.params.job_id != from.params.job_id) {
                this.setPageDetails();
            }
        }
    },
    methods: {
        ...mapActions({
            getJobDetail: 'report/getJobDetail',
            shareJobMarketing: 'report/shareJobMarketing',
        }),
        async setPageDetails(){
            this.isLoading = true;
            try {
                this.job_details = await this.getJobDetail(this.$route.params.job_id.toString());
            } catch (error) {
                notificationDanger(error);
            }
            this.isLoading = false;
        },
        back() {
            this.$router.push('/admin/marketing/jobs');
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
        async onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
            await this.shareJobMarketing(this.job_details._id);
        },
    }
};
</script>

<style scoped>
.text-detail{
    font-size: 19px;
    font-weight: 400;
}
.job-title{
    font-size: 26px;
    font-weight: 700;
}
</style>
